// Translated
// Migrated
<template lang="pug">
.form-group
  label(:for="id")
    slot(name="label")
  input.form-control.w-100(
    :id="id"
    :type="type"
    :name="id"
    :value="modelValue"
    :placeholder="placeholder"
    :class="{ 'is-invalid': isInvalid }"
    @input="$emit('update:modelValue', $event.target.value)"
    @keyup.enter="$emit('onClickEnter')"
  )
  .invalid-feedback(v-show="isInvalid")
    slot(name="validation" :v="v")
</template>

<script>
export default defineNuxtComponent({
  props: {
    v$: {
      type: Object,
      default: () => {},
    },

    id: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      default: 'text',
    },

    placeholder: {
      type: String,
      default: '',
    },

    modelValue: {
      type: String,
      default: '',
    },
  },

  emits: ['onClickEnter', 'update:modelValue'],

  computed: {
    v () {
      return this.v$?.[this.id] || { required: false }
    },

    shouldValidate () {
      return this.v$ && this.v$?.[this.id]
    },

    isInvalid () {
      if (!this.shouldValidate) {
        return false
      }
      return this.v$?.$errors.length > 0
    },
  },
})
</script>
